export default {
  GET_TEMPLATE_IN_PROGRESS: 'healthDataConsent/GET_TEMPLATE_IN_PROGRESS',
  GET_TEMPLATE_FULFILLED: 'healthDataConsent/GET_TEMPLATE_FULFILLED',
  GET_TEMPLATE_REJECTED: 'healthDataConsent/GET_TEMPLATE_REJECTED',
  GET_CONSENT_IN_PROGRESS: 'healthDataConsent/GET_CONSENT_IN_PROGRESS',
  GET_CONSENT_FULFILLED: 'healthDataConsent/GET_CONSENT_FULFILLED',
  GET_CONSENT_REJECTED: 'healthDataConsent/GET_CONSENT_REJECTED',
  ACCEPT_IN_PROGRESS: 'healthDataConsent/ACCEPT_IN_PROGRESS',
  ACCEPT_FULFILLED: 'healthDataConsent/ACCEPT_FULFILLED',
  ACCEPT_REJECTED: 'healthDataConsent/ACCEPT_REJECTED',
  DECLINE_IN_PROGRESS: 'healthDataConsent/DECLINE_IN_PROGRESS',
  DECLINE_FULFILLED: 'healthDataConsent/DECLINE_FULFILLED',
  DECLINE_REJECTED: 'healthDataConsent/DECLINE_REJECTED'
};
