import types from './types';
import initialState from './initialState';

const foodstuffMergeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOTAL_ITEMS_FULFILLED: {
      return {
        ...state,
        totalItemsToMerge: action.response.number,
        totalItemsApiError: null
      };
    }
    case types.TOTAL_ITEMS_REJECTED: {
      return {
        ...state,
        totalItemsToMerge: null,
        totalItemsApiError: action.response
      };
    }
    default:
      return state;
  }
};

export default foodstuffMergeReducer;
