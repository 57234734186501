import config from '../../config';

const svgs = require.context(`../../components/Navbar/logos/`, true, /\.svg$/);
const logo = svgs(`./logo-${config.productId}.svg`)?.default;

const initialState = {
  ...config.footer,
  logo
};

export default initialState;
