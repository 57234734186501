import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const setInputValue = value => ({
  type: types.SET_SEARCH_INPUT_VALUE,
  value
});

const clearInputValue = () => ({
  type: types.RESET_INPUT_VALUE
});

const clearSearchResult = () => ({
  type: types.RESET_SEARCH_RESULT
});

const clearDeletedSearchResult = id => ({
  type: types.RESET_DELETED_SEARCH_RESULT,
  id
});

const foodSearchPending = () => ({
  type: types.FOOD_SEARCH_PENDING
});

const foodSearchFulfilled = (response, searchString) => ({
  type: types.FOOD_SEARCH_FULFILLED,
  response,
  searchString
});

const foodSearchRejected = () => ({
  type: types.FOOD_SEARCH_REJECTED
});

function getSearchResult({ searchString, isFoodstuff, isRecipe, maxResult }) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response =>
      dispatch(foodSearchFulfilled(response, searchString));
    const rejected = error => {
      dispatch(foodSearchRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(foodSearchPending());

    return wsCalls().getFood(
      fulfilled,
      rejected,
      getUserId(),
      encodeURIComponent(searchString),
      isFoodstuff,
      isRecipe,
      maxResult
    );
  };
}

function getAllFoodstuffSearchResult({ searchString, maxResult }) {
  return (dispatch, _, wsCalls) => {
    const rejected = error => {
      dispatch(foodSearchRejected());
      dispatch(errorActions.errorHandler(error));
    };

    const fulfilled = response => {
      dispatch(foodSearchFulfilled(response, searchString));
    };

    dispatch(foodSearchPending());

    return wsCalls().getAllFoodstuffs(
      fulfilled,
      rejected,
      getUserId(),
      searchString,
      maxResult
    );
  };
}

export default {
  getSearchResult,
  getAllFoodstuffSearchResult,
  clearSearchResult,
  clearDeletedSearchResult,
  setInputValue,
  clearInputValue
};
