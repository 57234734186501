import get from 'lodash/get';
import find from 'lodash/find';

const activityNameLens = item => get(item, ['activity', 'name']);
const activityIdLens = item => get(item, ['activity', 'activityId']);
const strengthLens = item => get(item, ['activity', 'strength']);
const userExerciseActivityIdLens = item =>
  get(item, ['userExerciseActivityId']);
const durationMinutesLens = item => get(item, ['durationMinutes']);
const kcalLens = item => get(item, ['kcal']);
const kgLens = item => get(item, ['kg']);
const repsLens = item => get(item, ['reps']);
const setsLens = item => get(item, ['sets']);

function fastLogPayload(item) {
  const { durationMinutes, kg, reps, sets } = item;
  const { activityId } = item.activity;
  const activity = { activityId };
  return { activity, durationMinutes, kg, reps, sets };
}

const frequentlyLoggedExercise = (items, fastLoggedExerciseItems = []) =>
  items.map(item => ({
    content: {
      activityId: activityIdLens(item),
      name: activityNameLens(item),
      durationMinutes: durationMinutesLens(item),
      kcal: kcalLens(item),
      kg: kgLens(item),
      reps: repsLens(item),
      sets: setsLens(item),
      strength: strengthLens(item),
      itemId: userExerciseActivityIdLens(item),
      itemIdLogged: getUserExerciseActivityIdLogged(
        fastLoggedExerciseItems,
        activityIdLens(item)
      )
    },
    payload: fastLogPayload(item)
  }));

function getUserExerciseActivityIdLogged(fastLoggedExerciseItems, activityId) {
  const found = find(fastLoggedExerciseItems, { activityId });
  return found && found.userExerciseActivityId;
}

export default {
  activityIdLens,
  frequentlyLoggedExercise
};
