import { getUserId } from '@src/utils/helpers/authentication';
import localize from '@src/i18n';
import tracking from '@src/utils/helpers/tracking/tracking';
import modals from '@src/utils/constants/modals';
import { foodSearchActions } from '@ducks/foodSearch';
import { modalsActions } from '@ducks/modals';
import { errorActions } from '@ducks/error';
import types from './types';

const foodstuffPending = () => ({
  type: types.FOODSTUFF_PENDING
});

const foodstuffFulfilled = response => ({
  type: types.FOODSTUFF_FULFILLED,
  response
});

const foodstuffRejected = () => ({
  type: types.FOODSTUFF_REJECTED
});

const bulkFoodstuffPending = () => ({
  type: types.BULK_FOODSTUFF_PENDING
});

const bulkFoodstuffFulfilled = response => ({
  type: types.BULK_FOODSTUFF_FULFILLED,
  response
});

const bulkFoodstuffRejected = () => ({
  type: types.BULK_FOODSTUFF_REJECTED
});

const deleteFoodstuffPending = () => ({
  type: types.DELETE_FOODSTUFF_PENDING
});

const deleteFoodstuffFulfilled = foodstuffId => ({
  type: types.DELETE_FOODSTUFF_FULFILLED,
  id: foodstuffId
});

const deleteFoodstuffRejected = () => ({
  type: types.DELETE_FOODSTUFF_REJECTED
});

function getFoodstuffItem(foodstuffId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(foodstuffFulfilled(response));
    const rejected = error => {
      dispatch(foodstuffRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(foodstuffPending());

    return wsCalls().getFoodstuff(
      fulfilled,
      rejected,
      getUserId(),
      foodstuffId
    );
  };
}

function getBulkFoodstuffItems(foodstuffIds) {
  return (dispatch, _, wsCalls) => {
    const rejected = error => {
      dispatch(bulkFoodstuffRejected());
      dispatch(errorActions.errorHandler(error));
    };

    const foodstuffPromises = foodstuffIds.map(
      id =>
        new Promise((resolve, reject) => {
          wsCalls().getFoodstuff(resolve, reject, getUserId(), id);
        })
    );

    dispatch(bulkFoodstuffPending());
    return Promise.all(foodstuffPromises)
      .then(values => {
        dispatch(bulkFoodstuffFulfilled(values));
      })
      .catch(rejected);
  };
}

function deleteFoodstuffItem(foodstuffId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => {
      tracking.trackFoodstuffDeleted();
      dispatch(deleteFoodstuffFulfilled(foodstuffId));
      dispatch(
        modalsActions.showModal(modals.CONFIRMATION_MODAL, {
          text: localize('label_foodstuff_deleted') || 'Foodstuff removed'
        })
      );
      dispatch(modalsActions.hideModal(modals.LOG_FOODSTUFF_MODAL_ID));
      dispatch(foodSearchActions.clearDeletedSearchResult(foodstuffId));
    };
    const rejected = error => {
      dispatch(deleteFoodstuffRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(deleteFoodstuffPending());

    return wsCalls().deleteFoodstuffFromApi(
      fulfilled,
      rejected,
      getUserId(),
      foodstuffId
    );
  };
}

export default {
  getFoodstuffItem,
  getBulkFoodstuffItems,
  deleteFoodstuffItem
};
