import { modalsTypes } from '@ducks/modals';
import { recipeIngredientsTypes } from '@ducks/recipeIngredients';
import modals from '@src/utils/constants/modals';
import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const foodstuffReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FOODSTUFF_PENDING: {
      return { ...state, pending: true };
    }
    case types.FOODSTUFF_FULFILLED: {
      return {
        ...state,
        pending: false,
        foodstuffItem: action.response
      };
    }
    case types.FOODSTUFF_REJECTED: {
      return { ...state, pending: false };
    }
    case types.BULK_FOODSTUFF_PENDING: {
      return { ...state, bulkRequestStatus: requestStatus.pending };
    }
    case types.BULK_FOODSTUFF_FULFILLED: {
      return {
        ...state,
        bulkRequestStatus: requestStatus.fulfilled,
        bulkFoodstuffItems: action.response
      };
    }
    case types.BULK_FOODSTUFF_REJECTED: {
      return { ...state, bulkRequestStatus: requestStatus.rejected };
    }
    case types.DELETE_FOODSTUFF_PENDING: {
      return { ...state, deleteFoodstuffPending: true };
    }
    case types.DELETE_FOODSTUFF_FULFILLED: {
      return {
        ...state,
        deleteFoodstuffPending: false,
        id: action.id
      };
    }
    case types.DELETE_FOODSTUFF_REJECTED: {
      return { ...state, deleteFoodstuffPending: false };
    }
    case modalsTypes.HIDE_MODAL: {
      if (
        action.id === modals.LOG_FOODSTUFF_MODAL_ID ||
        (action.id === modals.ADD_RECIPE_FOODSTUFF_MODAL_ID &&
          !action.doNotDeleteAfter)
      ) {
        return { ...state, foodstuffItem: {} };
      }
      return state;
    }
    case recipeIngredientsTypes.ADD_INGREDIENT_IN_GROUP:
    case recipeIngredientsTypes.EDIT_INGREDIENT_IN_GROUP: {
      if (action.removeFoodstuff) {
        return { ...state, foodstuffItem: {} };
      }
      return state;
    }
    default:
      return state;
  }
};

export default foodstuffReducer;
