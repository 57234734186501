import get from 'lodash/get';
import { FOOD_TYPE } from '@comp/FoodstuffForm/helpers';
import { START_SEARCH_THRESHOLD } from '@src/utils/constants/search';

const idLens = item => get(item, ['id']);
const nameLens = item => get(item, ['name']);
const brandNameLens = item => get(item, ['brandName']);
const kcalLens = item => get(item, ['kcal']);
const foodTypeLens = item => get(item, ['foodType']);
const resTypeLens = item => get(item, ['resType']);
const imageLens = item => get(item, ['image']);
const imageVariantsLens = item => get(item, ['images', '0', 'variants']);
const positionLens = item => get(item, ['position']);
const foodSearchPendingLens = state => get(state, ['foodSearchPending']);
const foodSearchItemsLens = state => get(state, ['foodSearchItems']);
const foodSearchInputValueLens = state => get(state, ['foodSearchInputValue']);
const ratingAverageLens = state => get(state, ['ratingAverage']);

const recipeDefault = { amount: 1, unit: 'port' };
const foodstuffDefault = { amount: 100, unit: 'g' };

const isSearchValueAboveThreshold = state => {
  const searchInputValue = foodSearchInputValueLens(state);
  return searchInputValue
    ? searchInputValue?.length > START_SEARCH_THRESHOLD - 1
    : false;
};

const isSearchEmpty = state =>
  foodSearchItemsLens(state)?.length === 0 &&
  isSearchValueAboveThreshold(state) &&
  !foodSearchPendingLens(state);

const isRecipe = item => resTypeLens(item) === 'RECIPE';

const defaultAmount = recipe => (recipe ? recipeDefault : foodstuffDefault);

const getPositionDefault = item => positionLens(item) || undefined;

const getKcalAmount = (item, isItemRecipe) =>
  isItemRecipe ? recipeDefault.amount * kcalLens(item) : kcalLens(item);

const getLogAmount = isItemRecipe =>
  isItemRecipe ? recipeDefault.amount : null;

const searchResult = items =>
  items.map(item => {
    const isItemRecipe = isRecipe(item);
    const image = imageLens(item);

    return {
      foodId: parseInt(idLens(item), 10),
      name: nameLens(item),
      brandName: brandNameLens(item),
      kcalAmount: getKcalAmount(item, isItemRecipe),
      ...defaultAmount(isItemRecipe),
      logAmount: getLogAmount(isItemRecipe),
      isRecipe: isItemRecipe,
      hasImage: !!image,
      images: imageVariantsLens(item),
      position: getPositionDefault(item),
      ratingAverage: ratingAverageLens(item),
      isGenericFoodstuff:
        !brandNameLens(item) && foodTypeLens(item) === FOOD_TYPE.SYSTEM
    };
  });

export default {
  searchResult,
  isSearchEmpty,
  isSearchValueAboveThreshold
};
