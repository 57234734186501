import get from 'lodash/get';
import localize from '@src/i18n';

const idLens = item => get(item, ['id']);
const nameLens = item => get(item, ['name']);
const brandNameLens = item => get(item, ['brandName']);
const unitsLens = item => get(item, ['units'], []);
const kindLens = item => get(item, ['kind']);
const unitNameLens = item => get(item, ['unitName']);
const isFavouriteLens = item => get(item, ['isFavourite']);
const fatPercentLens = item => get(item, ['fatPercent']);
const carbohydratePercentLens = item => get(item, ['carbohydratePercent']);
const proteinPercentLens = item => get(item, ['proteinPercent']);
const alcoholPercentLens = item => get(item, ['alcoholPercent']);
const foodTypeLens = item => get(item, ['foodType']);
const visibilityStatusLens = item => get(item, ['visibilityStatus']);
const descriptivePackageSizeLens = item =>
  get(item, ['descriptivePackageSize']);
const properGramsPerUnitLens = item => get(item, ['properGramsPerUnit']);
const properGramsPerMilliliterLens = item => item?.properGramsPerMilliliter;

const isFoodstuffHidden = item => visibilityStatusLens(item) === 'HIDDEN';
const getDefaultAmount = () => 1;

const getPossibleUnits = item =>
  unitsLens(item).map(
    ({ name, kcalPerUnit, gramsPerCustomUnit, isLiquid }) => ({
      unitName: name,
      kcalPerUnit,
      gramsPerCustomUnit,
      isLiquid
    })
  );

const getFoodLog = (
  { foodstuffItem },
  isPostFavouriteFulfilled,
  isDeleteFavouriteFulfilled
) => {
  const possibleUnits = getPossibleUnits(foodstuffItem);

  return {
    id: idLens(foodstuffItem),
    name: nameLens(foodstuffItem),
    brandName: brandNameLens(foodstuffItem),
    possibleUnits,
    kind: kindLens(foodstuffItem),
    isSingleUnit: possibleUnits.length === 1,
    unitName: unitNameLens(foodstuffItem),
    foodType: foodTypeLens(foodstuffItem),
    isFoodstuffHidden: isFoodstuffHidden(foodstuffItem),
    isFavourite:
      (isPostFavouriteFulfilled && !isDeleteFavouriteFulfilled) ||
      (isFavouriteLens(foodstuffItem) && !isDeleteFavouriteFulfilled),
    descriptivePackageSize: descriptivePackageSizeLens(foodstuffItem),
    properGramsPerUnit: properGramsPerUnitLens(foodstuffItem),
    properGramsPerMilliliter: properGramsPerMilliliterLens(foodstuffItem)
  };
};

const getNutrition = foodstuffItem => {
  const fatPercent = getFatPercent(foodstuffItem);
  const carbohydratePercent = getCarbohydratePercent(foodstuffItem);
  const proteinPercent = getProteinPercent(foodstuffItem);
  const alcoholPercent = getAlcoholPercent(foodstuffItem);
  const maxValue = Math.max(fatPercent, carbohydratePercent, proteinPercent);

  const nutrition = [
    {
      id: 'fat',
      name: localize('label_nutrition_fat'),
      value: Math.round(fatPercent),
      base: getBase(maxValue, fatPercent)
    },
    {
      id: 'carbs',
      name: localize('label_nutrition_carbohydrates'),
      value: Math.round(carbohydratePercent),
      base: getBase(maxValue, carbohydratePercent)
    },
    {
      id: 'protein',
      name: localize('label_nutrition_protein'),
      value: Math.round(proteinPercent),
      base: getBase(maxValue, proteinPercent)
    }
  ];

  if (alcoholPercent) {
    const alcohol = {
      id: 'alcohol',
      name: localize('label_nutrition_alcohol'),
      value: Math.round(alcoholPercent),
      base: getBase(maxValue, alcoholPercent)
    };
    nutrition.push(alcohol);
  }

  return nutrition;
};

const isNutrition = foodstuffItem =>
  !!(
    getFatPercent(foodstuffItem) ||
    getCarbohydratePercent(foodstuffItem) ||
    getProteinPercent(foodstuffItem)
  );

// Use arbitrary base for the chart, i.e. 100
const getBase = (max, percent) =>
  Math.round(percent * (100 / max) * 10) / 10 || 0;

const getFatPercent = foodstuffItem => fatPercentLens(foodstuffItem) || 0;

const getCarbohydratePercent = foodstuffItem =>
  carbohydratePercentLens(foodstuffItem) || 0;

const getProteinPercent = foodstuffItem =>
  proteinPercentLens(foodstuffItem) || 0;

const getAlcoholPercent = foodstuffItem =>
  alcoholPercentLens(foodstuffItem) || 0;

export default {
  getFoodLog,
  getDefaultAmount,
  getNutrition,
  isNutrition
};
