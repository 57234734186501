import types from './types';
import initialState from './initialState';

const healthConsentReducer = (state = initialState, action) => {
  const responseTemplateContent = key => {
    if (action.response && action.localeKey) {
      const { localeKey } = action;
      const { contents = [] } = action.response;
      const [contentTranslated = {}] = contents.filter(data =>
        data.language.includes(localeKey)
      );
      return contentTranslated.content ? contentTranslated.content[key] : null;
    }
    return null;
  };

  const responseTemplateKey = () => {
    const { response: { key = {} } = {} } = action;
    return key;
  };

  const responseTemplateCode = () => {
    const { response: { code = '' } = {} } = action;
    return code;
  };

  switch (action.type) {
    case types.GET_IN_PROGRESS: {
      return { ...state, loading: true };
    }
    case types.GET_FULFILLED: {
      return {
        ...state,
        loading: false,
        title: responseTemplateContent('title'),
        description: responseTemplateContent('description'),
        content: responseTemplateContent('content'),
        boilerplate: responseTemplateContent('boilerplate'),
        templateKey: responseTemplateKey(),
        flowType: responseTemplateCode().includes('purchase-flow')
          ? 'new_users'
          : 'existing_users'
      };
    }
    case types.GET_FULFILLED_ALREADY_CONSENTED: {
      return { ...state, didAlreadyConsent: true };
    }
    case types.GET_REJECTED: {
      return { ...state, loading: false };
    }
    case types.ACCEPT_IN_PROGRESS: {
      return { ...state, loading: true };
    }
    case types.ACCEPT_REJECTED: {
      return { ...state, loading: false };
    }
    case types.ACCEPT_FULFILLED: {
      return {
        ...state,
        acceptStatus: {
          status: action.response.status,
          timestamp: Date.now()
        },
        loading: false
      };
    }
    case types.DECLINE_IN_PROGRESS: {
      return { ...state, loading: true };
    }
    case types.DECLINE_FULFILLED: {
      return {
        ...state,
        declineStatus: {
          status: action.response.status,
          timestamp: Date.now()
        },
        loading: false
      };
    }
    case types.DECLINE_REJECTED: {
      return { ...state, loading: false };
    }
    case types.CLEAR_CONSENT: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default healthConsentReducer;
