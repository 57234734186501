export default {
  GET_FOOD_PREFERENCES_FULFILLED:
    'foodPreferences/GET_FOOD_PREFERENCES_FULFILLED',
  GET_FOOD_PREFERENCES_REJECTED:
    'foodPreferences/GET_FOOD_PREFERENCES_REJECTED',
  GET_FOOD_PREFERENCES_PENDING: 'foodPreferences/GET_FOOD_PREFERENCES_PENDING',
  POST_FOOD_PREFERENCES_FULFILLED:
    'foodPreferences/POST_FOOD_PREFERENCES_FULFILLED',
  POST_FOOD_PREFERENCES_REJECTED:
    'foodPreferences/POST_FOOD_PREFERENCES_REJECTED',
  POST_FOOD_PREFERENCES_PENDING:
    'foodPreferences/POST_FOOD_PREFERENCES_PENDING',
  DELETE_FOOD_PREFERENCES_FULFILLED:
    'foodPreferences/DELETE_FOOD_PREFERENCES_FULFILLED',
  DELETE_FOOD_PREFERENCES_REJECTED:
    'foodPreferences/DELETE_FOOD_PREFERENCES_REJECTED',
  DELETE_FOOD_PREFERENCES_PENDING:
    'foodPreferences/DELETE_FOOD_PREFERENCES_PENDING'
};
