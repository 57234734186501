import { modalsTypes } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import types from './types';
import initialState from './initialState';

const historyFoodReducer = (state = initialState, action) => {
  const foodItems = () => action.response && action.response.foodItems;

  switch (action.type) {
    case types.BREAKFAST_PENDING: {
      return {
        ...state,
        breakfastItemsPending: true,
        breakfastFulfilled: false
      };
    }
    case types.BREAKFAST_FULFILLED: {
      return {
        ...state,
        breakfastItems: foodItems(),
        breakfastItemsPending: false,
        breakfastFulfilled: true
      };
    }
    case types.BREAKFAST_REJECTED: {
      return {
        ...state,
        breakfastItemsPending: false,
        breakfastFulfilled: false
      };
    }
    case types.BREAKFAST_EXPANDED: {
      return {
        ...state,
        breakfastExpanded: !state.breakfastExpanded
      };
    }
    case types.LUNCH_PENDING: {
      return { ...state, lunchItemsPending: true, lunchFulfilled: false };
    }
    case types.LUNCH_FULFILLED: {
      return {
        ...state,
        lunchItems: foodItems(),
        lunchItemsPending: false,
        lunchFulfilled: true
      };
    }
    case types.LUNCH_REJECTED: {
      return { ...state, lunchItemsPending: false, lunchFulfilled: false };
    }
    case types.LUNCH_EXPANDED: {
      return {
        ...state,
        lunchExpanded: !state.lunchExpanded
      };
    }
    case types.DINNER_PENDING: {
      return { ...state, dinnerItemsPending: true, dinnerFulfilled: false };
    }
    case types.DINNER_FULFILLED: {
      return {
        ...state,
        dinnerItems: foodItems(),
        dinnerItemsPending: false,
        dinnerFulfilled: true
      };
    }
    case types.DINNER_REJECTED: {
      return { ...state, dinnerItemsPending: false, dinnerFulfilled: false };
    }
    case types.DINNER_EXPANDED: {
      return {
        ...state,
        dinnerExpanded: !state.dinnerExpanded
      };
    }
    case types.SNACK_PENDING: {
      return { ...state, snackItemsPending: true, snackFulfilled: false };
    }
    case types.SNACK_FULFILLED: {
      return {
        ...state,
        snackItems: foodItems(),
        snackItemsPending: false,
        snackFulfilled: true
      };
    }
    case types.SNACK_REJECTED: {
      return { ...state, snackItemsPending: false, snackFulfilled: false };
    }
    case types.SNACK_EXPANDED: {
      return {
        ...state,
        snackExpanded: !state.snackExpanded
      };
    }
    case types.SUPPER_PENDING: {
      return { ...state, supperItemsPending: true, supperFulfilled: false };
    }
    case types.SUPPER_FULFILLED: {
      return {
        ...state,
        supperItems: foodItems(),
        supperItemsPending: false,
        supperFulfilled: true
      };
    }
    case types.SUPPER_REJECTED: {
      return { ...state, supperItemsPending: false, supperFulfilled: false };
    }
    case types.SUPPER_EXPANDED: {
      return {
        ...state,
        supperExpanded: !state.supperExpanded
      };
    }
    case modalsTypes.HIDE_MODAL: {
      if (action.id === modals.LOG_DRAWER_MODAL_ID) {
        return {
          ...state,
          breakfastExpanded: false,
          lunchExpanded: false,
          dinnerExpanded: false,
          snackExpanded: false
        };
      }
      return state;
    }
    case types.OWN_FOOD_EXPANDED: {
      return {
        ...state,
        ownFoodExpanded: !state.ownFoodExpanded
      };
    }
    default:
      return state;
  }
};

export default historyFoodReducer;
