import get from 'lodash/get';
import find from 'lodash/find';
import moment from 'moment';

const foodNameLens = item => get(item, ['food', 'name']);
const amountLens = item => get(item, ['amount']);
const brandNameLens = item => get(item, ['food', 'brandName']);
const foodIdLens = item => get(item, ['food', 'id']);
const foodImagesLens = item => get(item, ['food', 'images', '0', 'variants']);
const foodItemIdLens = item => get(item, ['foodItemId']);
const hasImageLens = item => get(item, ['food', 'hasImage']);
const foodKindLens = item => get(item, ['food', 'kind']);
const positionLens = item => get(item, ['position']);
const unitLens = item => get(item, ['unit']);
const kcalLens = item => get(item, ['kcal']);
const ratingAverageLens = item => get(item, ['ratingAverage']);

const isRecipe = item => foodKindLens(item) === 'RECIPE';

const fastLogPayload = item => {
  const { amount, position, unit } = item;
  const { kind, id } = item.food;
  const food = { kind, id };
  return { amount, food, position, unit };
};

// In order to get the `foodItemId` needed to delete a fast logged item, we store
// the `foodId`, `mealType` and `kind` from the item received from the history endpoint,
// along with the `foodItemId` received from the "fast log" API response. This is
// done in `fastLoggedFoodItems`.
const getFoodItemIdLogged = (
  fastLoggedFoodItems,
  foodId,
  mealType,
  kind,
  date
) => {
  const found = find(fastLoggedFoodItems, { foodId, mealType, kind, date });
  if (!found) return null;

  return found.foodItemId;
};

const frequentlyLoggedFood = (items, fastLoggedFoodItems = [], mealType) =>
  items.map(item => ({
    content: {
      name: foodNameLens(item),
      amount: amountLens(item),
      unit: unitLens(item),
      brandName: brandNameLens(item),
      kcalAmount: kcalLens(item),
      foodId: foodIdLens(item),
      itemId: foodItemIdLens(item),
      hasImage: hasImageLens(item),
      images: foodImagesLens(item),
      isRecipe: isRecipe(item),
      position: positionLens(item),
      mealType,
      itemIdLogged: getFoodItemIdLogged(
        fastLoggedFoodItems,
        foodIdLens(item),
        mealType,
        foodKindLens(item),
        moment().format('YYYYMMDD')
      ),
      ratingAverage: ratingAverageLens(item)
    },
    payload: fastLogPayload(item)
  }));

export default {
  foodKindLens,
  frequentlyLoggedFood,
  foodIdLens,
  getFoodItemIdLogged
};
