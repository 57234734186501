export default {
  FOODSTUFF_PENDING: 'foodstuff/FOODSTUFF_PENDING',
  FOODSTUFF_FULFILLED: 'foodstuff/FOODSTUFF_FULFILLED',
  FOODSTUFF_REJECTED: 'foodstuff/FOODSTUFF_REJECTED',
  BULK_FOODSTUFF_PENDING: 'foodstuff/BULK_FOODSTUFF_PENDING',
  BULK_FOODSTUFF_FULFILLED: 'foodstuff/BULK_FOODSTUFF_FULFILLED',
  BULK_FOODSTUFF_REJECTED: 'foodstuff/BULK_FOODSTUFF_REJECTED',
  DELETE_FOODSTUFF_PENDING: 'foodstuff/DELETE_FOODSTUFF_PENDING',
  DELETE_FOODSTUFF_FULFILLED: 'foodstuff/DELETE_FOODSTUFF_FULFILLED',
  DELETE_FOODSTUFF_REJECTED: 'foodstuff/DELETE_FOODSTUFF_REJECTED'
};
