import { modalsTypes } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import types from './types';
import initialState from './initialState';

const foodSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH_INPUT_VALUE: {
      return {
        ...state,
        foodSearchInputValue: action.value,
        foodSearchFulfilled: false
      };
    }
    case types.FOOD_SEARCH_PENDING: {
      return {
        ...state,
        foodSearchFulfilled: false,
        foodSearchPending: true
      };
    }
    case types.FOOD_SEARCH_FULFILLED: {
      return {
        ...state,
        foodSearchPending: false,
        foodSearchFulfilled: true,
        foodSearchItems:
          state.foodSearchInputValue === action.searchString
            ? action.response
            : state.foodSearchItems
      };
    }
    case types.FOOD_SEARCH_REJECTED: {
      return { ...state, foodSearchPending: false };
    }
    case types.RESET_SEARCH_RESULT: {
      return {
        ...state,
        foodSearchFulfilled: false,
        foodSearchItems: []
      };
    }
    case types.RESET_DELETED_SEARCH_RESULT: {
      return {
        ...state,
        foodSearchFulfilled: true,
        foodSearchItems: state.foodSearchItems.filter(
          ({ id }) => id !== action.id.toString()
        )
      };
    }
    case types.RESET_INPUT_VALUE: {
      return { ...state, foodSearchInputValue: '' };
    }
    case modalsTypes.HIDE_MODAL: {
      return action.id === modals.LOG_DRAWER_RECIPE_MODAL_ID
        ? initialState
        : state;
    }
    default:
      return state;
  }
};

export default foodSearchReducer;
