import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const weeklyMenuAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FOOD_PREFERENCES_PENDING: {
      return {
        ...state,
        getStatus: requestStatus.pending
      };
    }
    case types.GET_FOOD_PREFERENCES_FULFILLED: {
      return {
        ...state,
        preferences: action.response,
        getStatus: requestStatus.fulfilled
      };
    }
    case types.GET_FOOD_PREFERENCES_REJECTED: {
      return {
        ...state,
        getStatus: requestStatus.rejected,
        error: {
          message: action.error.message
        }
      };
    }
    case types.POST_FOOD_PREFERENCES_PENDING: {
      return {
        ...state,
        postStatus: requestStatus.pending
      };
    }
    case types.POST_FOOD_PREFERENCES_FULFILLED: {
      return {
        ...state,
        preferences: action.response,
        postStatus: requestStatus.fulfilled
      };
    }
    case types.POST_FOOD_PREFERENCES_REJECTED: {
      return {
        ...state,
        postStatus: requestStatus.rejected,
        error: {
          message: action.error.message
        }
      };
    }
    case types.DELETE_FOOD_PREFERENCES_PENDING: {
      return {
        ...state,
        deleteStatus: requestStatus.pending
      };
    }
    case types.DELETE_FOOD_PREFERENCES_FULFILLED: {
      return {
        ...state,
        preferences: initialState.preferences,
        deleteStatus: requestStatus.fulfilled
      };
    }
    case types.DELETE_FOOD_PREFERENCES_REJECTED: {
      return {
        ...state,
        deleteStatus: requestStatus.rejected,
        error: {
          message: action.error.message
        }
      };
    }
    default:
      return state;
  }
};

export default weeklyMenuAdminReducer;
