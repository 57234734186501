import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions, errorSelectors } from '@ducks/error';
import { modalsActions } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import localize from '@src/i18n/i18n';
import types from './types';

const getFoodPreferencesFulfilled = response => ({
  type: types.GET_FOOD_PREFERENCES_FULFILLED,
  response
});

const getFoodPreferencesRejected = error => ({
  type: types.GET_FOOD_PREFERENCES_REJECTED,
  error: {
    message: error.message
  }
});

const getFoodPreferencesPending = () => ({
  type: types.GET_FOOD_PREFERENCES_PENDING
});

const postFoodPreferencesFulfilled = response => ({
  type: types.POST_FOOD_PREFERENCES_FULFILLED,
  response
});

const postFoodPreferencesRejected = error => ({
  type: types.POST_FOOD_PREFERENCES_REJECTED,
  error: {
    message: error.message
  }
});

const postFoodPreferencesPending = () => ({
  type: types.POST_FOOD_PREFERENCES_PENDING
});

const deleteFoodPreferencesPending = () => ({
  type: types.DELETE_FOOD_PREFERENCES_PENDING
});

const deleteFoodPreferencesFulfilled = () => ({
  type: types.DELETE_FOOD_PREFERENCES_FULFILLED
});

const deleteFoodPreferencesRejected = error => ({
  type: types.DELETE_FOOD_PREFERENCES_REJECTED,
  error: {
    message: error.message
  }
});

function getFoodPreferences() {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response =>
      dispatch(getFoodPreferencesFulfilled(response));
    const rejected = error => {
      if (errorSelectors.statusLens(error) === 404) {
        dispatch(getFoodPreferencesFulfilled(null));
      } else {
        dispatch(getFoodPreferencesRejected(error));
        dispatch(errorActions.errorHandler(error));
      }
    };

    dispatch(getFoodPreferencesPending());
    return wsCalls().getFoodPreferences(fulfilled, rejected, getUserId());
  };
}

function postFoodPreferences(payload, shouldDisplayToast) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => {
      dispatch(postFoodPreferencesFulfilled(payload));
      if (shouldDisplayToast)
        dispatch(
          modalsActions.showModal(modals.CONFIRMATION_MODAL, {
            text: localize('label_menu_preferences_saved')
          })
        );
    };
    const rejected = error => {
      dispatch(postFoodPreferencesRejected(error));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(postFoodPreferencesPending());
    return wsCalls().postFoodPreferences(
      fulfilled,
      rejected,
      getUserId(),
      payload,
      true
    );
  };
}

function deleteFoodPreferences() {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response =>
      dispatch(deleteFoodPreferencesFulfilled(response));
    const rejected = error => {
      dispatch(deleteFoodPreferencesRejected(error));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(deleteFoodPreferencesPending());
    return wsCalls().deleteFoodPreferences(fulfilled, rejected, getUserId());
  };
}

export default {
  getFoodPreferences,
  postFoodPreferences,
  deleteFoodPreferences
};
