import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import dependencies from '@src/utils/helpers/dependencies';
import tracking from '@src/utils/helpers/tracking/tracking';
import { modalsActions } from '@ducks/modals';
import { userActions } from '@ducks/user';
import modals from '@src/utils/constants/modals';
import types from './types';

const getTemplateInProgress = () => ({
  type: types.GET_TEMPLATE_IN_PROGRESS
});

const getTemplateFulfilled = (response, localeKey) => ({
  type: types.GET_TEMPLATE_FULFILLED,
  response,
  localeKey
});

const getTemplateRejected = () => ({
  type: types.GET_TEMPLATE_REJECTED
});

const acceptInProgress = () => ({
  type: types.ACCEPT_IN_PROGRESS
});

const acceptFulfilled = response => ({
  type: types.ACCEPT_FULFILLED,
  response
});

const acceptRejected = () => ({
  type: types.ACCEPT_REJECTED
});

const declineInProgress = () => ({
  type: types.DECLINE_IN_PROGRESS
});

const declineFulfilled = response => ({
  type: types.DECLINE_FULFILLED,
  response
});

const declineRejected = () => ({
  type: types.DECLINE_REJECTED
});

function getHealthDataConsentTemplate() {
  return (dispatch, _, wsCalls) => {
    const rejected = () => {
      dispatch(getTemplateRejected());
      dispatch(modalsActions.hideModal(modals.HEALTH_DATA_CONSENT_MODAL));
    };

    const fulfilled = response => {
      dispatch(
        getTemplateFulfilled(
          response,
          dependencies.get(dependencies.keys.DATE_FNS_KEY)
        )
      );
    };

    dispatch(getTemplateInProgress());

    return wsCalls().getHealthDataConsentTemplate(
      fulfilled,
      rejected,
      getUserId()
    );
  };
}

function acceptHealthDataConsent(templateKey) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(acceptFulfilled(response));
      dispatch(userActions.getHealthDataConsent());
      tracking.configureTrackingState('CONSENTED', true);
      tracking.trackHealthConsentForProductImprovementAccepted();
      dispatch(modalsActions.hideModal(modals.HEALTH_DATA_CONSENT_MODAL));
    };
    const rejected = error => {
      dispatch(acceptRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(acceptInProgress());

    return wsCalls().acceptHealthDataConsent(fulfilled, rejected, getUserId(), {
      templateKey
    });
  };
}

function declineHealthDataConsent(templateKey) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(declineFulfilled(response));
      dispatch(userActions.getHealthDataConsent());
      tracking.configureTrackingState('DECLINED', true);
      tracking.trackHealthConsentForProductImprovementDeclined();
      dispatch(modalsActions.hideModal(modals.HEALTH_DATA_CONSENT_MODAL));
    };
    const rejected = error => {
      dispatch(declineRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(declineInProgress());

    return wsCalls().declineHealthDataConsent(
      fulfilled,
      rejected,
      getUserId(),
      {
        templateKey
      }
    );
  };
}

export default {
  getHealthDataConsentTemplate,
  acceptHealthDataConsent,
  declineHealthDataConsent
};
