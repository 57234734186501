import { modalsTypes } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import types from './types';
import initialState from './initialState';

const historyExerciseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EXERCISE_PENDING: {
      return { ...state, exerciseItemsPending: true, exerciseFulfilled: false };
    }
    case types.EXERCISE_FULFILLED: {
      return {
        ...state,
        exerciseItems: action.response && action.response.exerciseItems,
        exerciseItemsPending: false,
        exerciseFulfilled: true
      };
    }
    case types.EXERCISE_REJECTED: {
      return {
        ...state,
        exerciseItemsPending: false,
        exerciseFulfilled: false
      };
    }
    case types.EXERCISE_EXPANDED: {
      return {
        ...state,
        exerciseExpanded: !state.exerciseExpanded
      };
    }
    case modalsTypes.HIDE_MODAL: {
      if (action.id === modals.LOG_DRAWER_MODAL_ID) {
        return {
          ...state,
          exerciseExpanded: false
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default historyExerciseReducer;
