import types from './types';
import initialState from './initialState';

const healthDataConsentReducer = (state = initialState, action) => {
  const responseTemplateContent = key => {
    if (action.response && action.localeKey) {
      const { localeKey } = action;
      const { contents = [] } = action.response;
      const [contentTranslated = {}] = contents.filter(data =>
        data.language.includes(localeKey)
      );
      return contentTranslated.content ? contentTranslated.content[key] : null;
    }
    return null;
  };

  const responseTemplateKey = () => {
    const { response: { key = {} } = {} } = action;
    return key;
  };

  switch (action.type) {
    case types.GET_TEMPLATE_IN_PROGRESS: {
      return { ...state, loading: true };
    }
    case types.GET_TEMPLATE_FULFILLED: {
      return {
        ...state,
        loading: false,
        boilerplate: responseTemplateContent('boilerplate'),
        content: responseTemplateContent('content'),
        templateKey: responseTemplateKey(),
        fulfilled: true
      };
    }
    case types.GET_TEMPLATE_REJECTED: {
      return { ...state, loading: false, fulfilled: false };
    }
    case types.ACCEPT_IN_PROGRESS: {
      return { ...state, loading: true };
    }
    case types.ACCEPT_REJECTED: {
      return { ...state, loading: false };
    }
    case types.ACCEPT_FULFILLED: {
      return {
        ...state,
        loading: false
      };
    }
    case types.DECLINE_IN_PROGRESS: {
      return { ...state, loading: true };
    }
    case types.DECLINE_FULFILLED: {
      return {
        ...state,
        loading: false
      };
    }
    case types.DECLINE_REJECTED: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default healthDataConsentReducer;
