export default {
  breakfastItems: [],
  breakfastItemsPending: false,
  breakfastFulfilled: false,
  breakfastExpanded: false,
  lunchItems: [],
  lunchItemsPending: false,
  lunchFulfilled: false,
  lunchExpanded: false,
  dinnerItems: [],
  dinnerItemsPending: false,
  dinnerFulfilled: false,
  dinnerExpanded: false,
  snackItems: [],
  snackItemsPending: false,
  snackFulfilled: false,
  snackExpanded: false,
  supperItems: [],
  supperItemsPending: false,
  supperFulfilled: false,
  supperExpanded: false,
  ownFoodExpanded: false
};
