export default {
  loading: false,
  title: '',
  description: '',
  content: '',
  boilerplate: '',
  acceptStatus: {},
  declineStatus: {},
  didAlreadyConsent: false,
  flowType: undefined
};
