import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import dependencies from '@src/utils/helpers/dependencies';
import types from './types';

const getInProgress = () => ({
  type: types.GET_IN_PROGRESS
});

const getFulfilled = (response, localeKey) => ({
  type: types.GET_FULFILLED,
  response,
  localeKey
});

const getFilfilledAlreadyConsented = () => ({
  type: types.GET_FULFILLED_ALREADY_CONSENTED
});

const getRejected = () => ({
  type: types.GET_REJECTED
});

const acceptInProgress = () => ({
  type: types.ACCEPT_IN_PROGRESS
});

const acceptFulfilled = response => ({
  type: types.ACCEPT_FULFILLED,
  response
});

const acceptRejected = () => ({
  type: types.ACCEPT_REJECTED
});

const declineInProgress = () => ({
  type: types.DECLINE_IN_PROGRESS
});

const declineFulfilled = response => ({
  type: types.DECLINE_FULFILLED,
  response
});

const declineRejected = () => ({
  type: types.DECLINE_REJECTED
});

const clearConsent = () => ({
  type: types.CLEAR_CONSENT
});

function clearHealthConsent() {
  return dispatch => {
    dispatch(clearConsent());
  };
}

function getHealthConsent() {
  return (dispatch, _, wsCalls) => {
    const rejected = error => {
      dispatch(getRejected());
      dispatch(errorActions.errorHandler(error));
    };

    const fulfilled = response => {
      if (response.status === 200) {
        response
          .json()
          .then(json => {
            dispatch(
              getFulfilled(
                json,
                dependencies.get(dependencies.keys.DATE_FNS_KEY)
              )
            );
          })
          .catch(rejected);
      } else if (response.status === 204) {
        dispatch(getFilfilledAlreadyConsented());
      }
    };

    dispatch(getInProgress());

    return wsCalls().getHealthConsent(fulfilled, rejected, getUserId());
  };
}

function acceptHealthConsent(templateKey) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(acceptFulfilled(response));
    const rejected = error => {
      dispatch(acceptRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(acceptInProgress());

    return wsCalls().acceptHealthConsent(fulfilled, rejected, getUserId(), {
      templateKey
    });
  };
}

function declineHealthConsent(templateKey) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(declineFulfilled(response));
    const rejected = error => {
      dispatch(declineRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(declineInProgress());

    return wsCalls().declineHealthConsent(fulfilled, rejected, getUserId(), {
      templateKey
    });
  };
}

export default {
  getHealthConsent,
  acceptHealthConsent,
  declineHealthConsent,
  clearHealthConsent
};
