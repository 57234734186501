import { getUserId } from '@src/utils/helpers/authentication';
import types from './types';

const totalItemsFulfilled = response => ({
  type: types.TOTAL_ITEMS_FULFILLED,
  response
});

const totalItemsRejected = response => ({
  type: types.TOTAL_ITEMS_REJECTED,
  response
});

function getTotalItemsToMerge() {
  return (dispatch, _, wsCalls) => {
    const onSuccess = response => dispatch(totalItemsFulfilled(response));
    const onFail = response => dispatch(totalItemsRejected(response));
    return wsCalls().getNumberOfLeftFoodstuffForMerge(
      getUserId(),
      onSuccess,
      onFail
    );
  };
}

export default {
  getTotalItemsToMerge
};
