export default {
  GET_IN_PROGRESS: 'healthConsent/GET_IN_PROGRESS',
  GET_FULFILLED: 'healthConsent/GET_FULFILLED',
  GET_FULFILLED_ALREADY_CONSENTED:
    'healthConsent/GET_FULFILLED_ALREADY_CONSENTED',
  GET_REJECTED: 'healthConsent/GET_REJECTED',
  ACCEPT_IN_PROGRESS: 'healthConsent/ACCEPT_IN_PROGRESS',
  ACCEPT_FULFILLED: 'healthConsent/ACCEPT_FULFILLED',
  ACCEPT_REJECTED: 'healthConsent/ACCEPT_REJECTED',
  DECLINE_IN_PROGRESS: 'healthConsent/DECLINE_IN_PROGRESS',
  DECLINE_FULFILLED: 'healthConsent/DECLINE_FULFILLED',
  DECLINE_REJECTED: 'healthConsent/DECLINE_REJECTED',
  CLEAR_CONSENT: 'healthConsent/CLEAR'
};
