import { getUserId } from '@src/utils/helpers/authentication';
import { getHistoryExercise } from '@src/utils/helpers/wsCalls';
import { errorActions } from '@ducks/error';
import types from './types';

const exercisePending = () => ({
  type: types.EXERCISE_PENDING
});

const exerciseFulfilled = response => ({
  type: types.EXERCISE_FULFILLED,
  response
});

const exerciseRejected = () => ({
  type: types.EXERCISE_REJECTED
});

export default {
  getHistoryExercise: () => dispatch => {
    const fulfilled = response => dispatch(exerciseFulfilled(response));
    const rejected = error => {
      dispatch(exerciseRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(exercisePending());
    return getHistoryExercise(fulfilled, rejected, getUserId());
  },
  exerciseExpanded: () => ({
    type: types.EXERCISE_EXPANDED
  })
};
