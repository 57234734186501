import { getUserId } from '@src/utils/helpers/authentication';
import { getHistoryFood } from '@src/utils/helpers/wsCalls';
import { errorActions } from '@ducks/error';
import types from './types';

const ownFoodExpanded = () => ({
  type: types.OWN_FOOD_EXPANDED
});

const breakfastPending = () => ({
  type: types.BREAKFAST_PENDING
});

const breakfastFulfilled = response => ({
  type: types.BREAKFAST_FULFILLED,
  response
});

const breakfastRejected = () => ({
  type: types.BREAKFAST_REJECTED
});

const breakfastExpanded = () => ({
  type: types.BREAKFAST_EXPANDED
});

const lunchPending = () => ({
  type: types.LUNCH_PENDING
});

const lunchFulfilled = response => ({
  type: types.LUNCH_FULFILLED,
  response
});

const lunchRejected = () => ({
  type: types.LUNCH_REJECTED
});

const lunchExpanded = () => ({
  type: types.LUNCH_EXPANDED
});

const dinnerPending = () => ({
  type: types.DINNER_PENDING
});

const dinnerFulfilled = response => ({
  type: types.DINNER_FULFILLED,
  response
});

const dinnerRejected = () => ({
  type: types.DINNER_REJECTED
});

const dinnerExpanded = () => ({
  type: types.DINNER_EXPANDED
});

const snackPending = () => ({
  type: types.SNACK_PENDING
});

const snackFulfilled = response => ({
  type: types.SNACK_FULFILLED,
  response
});

const snackRejected = () => ({
  type: types.SNACK_REJECTED
});

const snackExpanded = () => ({
  type: types.SNACK_EXPANDED
});

const supperPending = () => ({
  type: types.SUPPER_PENDING
});

const supperFulfilled = response => ({
  type: types.SUPPER_FULFILLED,
  response
});

const supperRejected = () => ({
  type: types.SUPPER_REJECTED
});

const supperExpanded = () => ({
  type: types.SUPPER_EXPANDED
});

function fetchHistoryFood(mealType, pending, foodFulfilled, foodRejected) {
  return dispatch => {
    const fulfilled = response => dispatch(foodFulfilled(response));
    const rejected = error => {
      dispatch(foodRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(pending());
    return getHistoryFood(fulfilled, rejected, getUserId(), mealType);
  };
}

const fetchHistoryBreakfast = () =>
  fetchHistoryFood(
    'breakfast',
    breakfastPending,
    breakfastFulfilled,
    breakfastRejected
  );

const fetchHistoryLunch = () =>
  fetchHistoryFood('lunch', lunchPending, lunchFulfilled, lunchRejected);

const fetchHistoryDinner = () =>
  fetchHistoryFood('dinner', dinnerPending, dinnerFulfilled, dinnerRejected);

const fetchHistorySnack = () =>
  fetchHistoryFood('snack', snackPending, snackFulfilled, snackRejected);

const fetchHistorySupper = () =>
  fetchHistoryFood('supper', supperPending, supperFulfilled, supperRejected);

export default {
  fetchHistoryBreakfast,
  fetchHistoryLunch,
  fetchHistoryDinner,
  fetchHistorySupper,
  fetchHistorySnack,
  breakfastExpanded,
  lunchExpanded,
  dinnerExpanded,
  snackExpanded,
  supperExpanded,
  ownFoodExpanded
};
