export default {
  BREAKFAST_FULFILLED: 'historyFood/BREAKFAST_FULFILLED',
  BREAKFAST_REJECTED: 'historyFood/BREAKFAST_REJECTED',
  BREAKFAST_PENDING: 'historyFood/BREAKFAST_PENDING',
  BREAKFAST_EXPANDED: 'historyFood/BREAKFAST_EXPANDED',
  LUNCH_FULFILLED: 'historyFood/LUNCH_FULFILLED',
  LUNCH_REJECTED: 'historyFood/LUNCH_REJECTED',
  LUNCH_PENDING: 'historyFood/LUNCH_PENDING',
  LUNCH_EXPANDED: 'historyFood/LUNCH_EXPANDED',
  DINNER_FULFILLED: 'historyFood/DINNER_FULFILLED',
  DINNER_REJECTED: 'historyFood/DINNER_REJECTED',
  DINNER_PENDING: 'historyFood/DINNER_PENDING',
  DINNER_EXPANDED: 'historyFood/DINNER_EXPANDED',
  SNACK_FULFILLED: 'historyFood/SNACK_FULFILLED',
  SNACK_REJECTED: 'historyFood/SNACK_REJECTED',
  SNACK_PENDING: 'historyFood/SNACK_PENDING',
  SNACK_EXPANDED: 'historyFood/SNACK_EXPANDED',
  SUPPER_FULFILLED: 'historyFood/SUPPER_FULFILLED',
  SUPPER_REJECTED: 'historyFood/SUPPER_REJECTED',
  SUPPER_PENDING: 'historyFood/SUPPER_PENDING',
  SUPPER_EXPANDED: 'historyFood/SUPPER_EXPANDED',
  OWN_FOOD_EXPANDED: 'historyFood/OWN_FOOD_EXPANDED'
};
